<template>
	<div>
		<a-tabs default-active-key="1">
			<a-tab-pane key="1" tab="Finanzas" v-if="allowShowGraphic">
				<div>
					<div class="row">
						<div class="col-md-2">
							<h2 class="font-size-18 font-weight-bold mb-1 text-dark">Año</h2>
							<a-select v-model="year" :style="{ width: '100%' }" @change="initModule">
								<a-select-option value="2024"> 2024 </a-select-option>
								<a-select-option value="2025"> 2025 </a-select-option>
							</a-select>
							<!-- <button @click="initModule">initModule()</button> -->
						</div>
						<div class="col-md-2">
							<h2 class="font-size-18 font-weight-bold mb-1 text-dark">Semestre</h2>
							<a-select v-model="semester" :style="{ width: '100%' }" @change="initModule">
								<a-select-option value="1"> Primer semestre </a-select-option>
								<a-select-option value="2"> Segundo semestre </a-select-option>
							</a-select>
						</div>
						<div class="col-md-2">
							<h2 class="font-size-18 font-weight-bold mb-1 text-dark">Segmentación</h2>
							<a-select v-model="segment" :style="{ width: '100%' }" @change="initModule">
								<a-select-option value="q"> Quincenal </a-select-option>
								<a-select-option value="m"> Mensual </a-select-option>
							</a-select>
						</div>
						<div class="col-md-6">
							<div class="text-primary font-weight-bold font-size-24 text-right">
								Meta semestral ${{ dashboardReport.semestralGoal / 1000000 }}K
								<div class="font-size-18" style="color: #f5222e">~ ${{ numeral(dashboardReport.average).format('0,0') }}</div>
							</div>
						</div>
					</div>
					<div class="mb-3 pt10" v-if="dashboardReport.granTotal > 0">
						<vue-c3 :handler="bar" class="height-300"></vue-c3>
						<a-progress
							type="line"
							:showInfo="false"
							:strokeWidth="30"
							status="active"
							:strokeColor="{
								'0%': '#f8d7da',
								'50%': '#fff3cd',
								'100%': '#d1e7dd',
							}"
							:percent="dashboardReport.percent"
						/>
					</div>
					<div class="d-flex text-gray-5 justify-content-between font-size-14">
						<span class="text-uppercase">{{ dashboardReport.percent }}% -- {{ numeral(dashboardReport.granTotalGroup).format('$0,0.00') }}</span>
					</div>
					<hr class="hrText" data-content="♦" />
				</div>
				<div class="row">
					<div class="col-md-12 text-center">
						<div class="card bgSoftGreen">
							<div class="card-body">
								<div class="font-weight-bold">
									<div class="font-size-21">Ingresos por ventas</div>
									<div class="font-size-18">{{ numeral(totalIngresos).format('$0,0.00') }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-md-12 text-center" v-if="allowShowCash">
						<div class="card bgRls border-0">
							<div class="card-body">
								<div class="font-weight-bold">
									<div class="font-size-21">Productos</div>
									<div class="font-size-14">
										<div class="row">
											<div class="col-md-7 text-left">
												<div>Ingresos</div>
											</div>
											<div class="col-md-5 text-right">
												{{ numeral(dashboardReport.rls.in).format('$0,0.00') }}
											</div>
										</div>
										<div class="row">
											<div class="col-md-7 text-left">
												<div>Egresos</div>
											</div>
											<div class="col-md-5 text-right">
												{{ numeral(dashboardReport.rls.out).format('$0,0.00') }}
											</div>
										</div>
										<div class="row p0">
											<div class="col-md-12">
												<hr />
											</div>
										</div>
										<div class="row">
											<div class="col-md-7 text-left">
												<div>Ganancia</div>
											</div>
											<div class="col-md-5 text-right">
												{{ numeral(dashboardReport.rls.in - dashboardReport.rls.out).format('$0,0.00') }}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</a-tab-pane>
			<a-tab-pane key="2" tab="Asistencias" force-render v-if="[1, 2, 3, 27].includes(user.user_id)">
				<attendanceRecord />
			</a-tab-pane>
		</a-tabs>
	</div>
</template>

<script>
import Vue from 'vue'
import VueC3 from 'vue-c3'
import 'c3/c3.min.css'
import { mapGetters } from 'vuex'
import numeral from 'numeral'
import _ from 'lodash'
import moment from 'moment-timezone'
import utilities from '@/services/utilities'
import attendanceRecord from '@/components/attendanceRecord'
import { cfdiUseList } from '@/constants'
//
const dateFormat = 'YYYY-MM-DD'
moment.tz.setDefault('America/Mexico_City')

export default {
	name: 'directionDashboard',
	components: {
		attendanceRecord,
		VueC3,
	},
	computed: {
		...mapGetters('direction', ['dashboardReport', 'getTechnicProfitReport', 'filters']),
		...mapGetters('user', ['user']),
		allowShowGraphic() {
			let allowedUserPermition = [1, 2, 3]
			return !!allowedUserPermition.includes(this.user.user_id)
		},
		allowShowCash() {
			let allowedUserPermition = [1, 2, 3, 17, 27]
			return !!allowedUserPermition.includes(this.user.user_id)
		},
		totalIngresos() {
			return utilities.objectValidate(this.dashboardReport, 'pacific.in', 0)
		},
		totalEgresos() {
			return utilities.objectValidate(this.dashboardReport, 'pacific.out', 0)
		},
		barOptions() {
			// ['Productos', 'Promedio', 'Total']
			return {
				data: {
					json: utilities.objectValidate(this.dashboardReport, 'groupData.labels', []).map((e, i) => {
						return {
							name: e,
							Promedio: this.dashboardReport.groupData.series[0][i].value,
							Productos: this.dashboardReport.groupData.series[1][i].value,
							Total: this.dashboardReport.groupData.series[1][i].value,
						}
					}),
					keys: {
						x: 'name',
						value: ['Productos', 'Promedio', 'Total'],
					},
					type: 'bar',
					types: {
						Total: 'spline',
						Promedio: 'spline',
					},
				},
				bar: {
					width: {
						max: 20,
					},
				},
				axis: {
					x: {
						type: 'category',
					},
					y: {
						show: !1,
					},
				},
				tooltip: {
					format: {
						value: function (value, ratio, id) {
							return numeral(value).format('$0,0.00')
						},
					},
				},
				grid: {
					y: {
						show: !0,
					},
					x: {
						show: !1,
					},
				},
				color: {
					// ['Productos', 'Promedio', 'Total']
					pattern: ['#2b2f32', '#f5222e', 'green'],
				},
			}
		},
	},
	data() {
		return {
			activeTab: 'general', // general / available
			dateFormat,
			defaultDate: [moment().startOf('month').format(dateFormat), moment(moment().startOf('month').add(13, 'days')).format(dateFormat)],
			semester: moment().month() < 6 ? '1' : '2',
			year: moment().year(),
			segment: 'q',
			//
			bar: new Vue(),
			colors: {
				primary: '#01a8fe',
				def: '#acb7bf',
				success: '#46be8a',
				danger: '#fb434a',
			},
			columns: [
				{
					title: '# de ODC',
					fixed: 'left',
					dataIndex: 'id',
					align: 'center',
					width: 100,
				},
				{
					title: 'Fecha de creación',
					scopedSlots: { customRender: 'created_at' },
					align: 'center',
					width: 130,
				},
				{
					title: 'Fecha de pago',
					scopedSlots: { customRender: 'payment_date' },
					align: 'center',
					width: 130,
				},
				{
					title: 'RFC proveedor',
					dataIndex: 'rfc',
					align: 'center',
					width: 150,
				},
				{
					title: 'Razón Social',
					scopedSlots: { customRender: 'business_name' },
					align: 'center',
					width: 250,
				},
				{
					title: 'Concepto',
					scopedSlots: { customRender: 'elements' },
					align: 'center',
					width: 500,
				},
				{
					title: 'Forma de pago',
					scopedSlots: { customRender: 'payment_method_data' },
					align: 'center',
					width: 150,
				},
				{
					title: 'Subtotal',
					scopedSlots: { customRender: 'subtotal' },
					align: 'center',
					width: 100,
				},
				{
					title: 'Descuento',
					scopedSlots: { customRender: 'general_discount' },
					align: 'center',
					width: 100,
				},
				{
					title: 'IVA acreditable',
					scopedSlots: { customRender: 'iva' },
					align: 'center',
					width: 100,
				},
				{
					title: 'Retención de ISR',
					scopedSlots: { customRender: 'ret_isr' },
					align: 'center',
					width: 100,
				},
				{
					title: 'Retención de IVA',
					scopedSlots: { customRender: 'ret_iva' },
					align: 'center',
					width: 100,
				},
				{
					title: 'Total',
					scopedSlots: { customRender: 'total' },
					align: 'center',
					width: 100,
				},
				{
					title: 'Estatus',
					scopedSlots: { customRender: 'status' },
					align: 'center',
					width: 100,
				},
			],
			loanPurchaseOrders: {
				visible: false,
				elements: [],
			},
		}
	},
	destroyed() {
		this.clearData()
	},
	mounted() {
		this.initModule()
	},
	methods: {
		numeral,
		moment,
		onCloseModal() {
			this.loanPurchaseOrders = {
				visible: false,
				elements: [],
			}
		},
		getIVA(id) {
			let theRecord = this.loanPurchaseOrders.elements.find((e) => e.id == id)
			if (utilities.objectValidate(theRecord, 'general_discount', false)) {
				if (theRecord.discount_type == 'after') {
					return numeral(((theRecord.total + theRecord.general_discount) / 1.16) * 0.16).format('0.00')
				} else {
					return numeral((theRecord.total / 1.16) * 0.16).format('0.00')
				}
			} else {
				return numeral((theRecord.total / 1.16) * 0.16).format('0.00')
			}
			return ''
		},
		getSubtotal(id) {
			let theRecord = this.loanPurchaseOrders.elements.find((e) => e.id == id)
			if (utilities.objectValidate(theRecord, 'general_discount', false)) {
				if (theRecord.discount_type == 'after') {
					return numeral((theRecord.total + theRecord.general_discount) / 1.16).format('0.00')
				} else {
					return numeral(theRecord.total / 1.16).format('0.00')
				}
			} else {
				return numeral(theRecord.total / 1.16).format('0.00')
			}
			return ''
		},
		getPaymentMethod(value) {
			if (value != '') {
				let paymentMethodData = JSON.parse(value)
				return numeral(paymentMethodData.payment_method).value() == 1 ? 'Efectivo' : 'Transferencia Bancaria'
			}
			return ''
		},
		getcfdiUse(code) {
			if (code) {
				return cfdiUseList.find((e) => e.key == code).label
			}
			return ''
		},
		getODCStatus(value) {
			switch (value) {
				case 'C':
					return 'Cotización'
				case 0:
					return 'Por autorizar'
				case 1:
					return 'Por pagar'
				case 1.1:
					return 'Por pagar'
				case 2:
					return 'Pagadas'
				case 3:
					return 'Facturadas'
				case 4:
					return 'Validadas'
				default:
					return ''
					break
			}
		},
		getLoanPurchaseOrders() {
			// console.log('getLoanPurchaseOrders');
			this.$store.dispatch('direction/GET_LOAN_PURCHASE_ORDERS').then((response) => {
				// console.log('response-->', response);
				this.loanPurchaseOrders = {
					elements: _.cloneDeep(response.data),
					visible: true,
				}
			})
		},
		clearData() {
			this.$store.commit('direction/SET_STATE', {
				dashboardReport: {
					semestralGoal: 0,
					average: 0,
					panel: {
						in: 0,
						out: 0,
					},
					pacific: {
						in: 0,
						out: 0,
					},
					rls: {
						in: 0,
						out: 0,
					},
					loans: {
						in: 0,
						out: 0,
					},
				},
			})
		},
		initModule() {
			this.clearData()
			this.$store.dispatch('direction/GET_DASHBOARD_REPORT', {
				year: this.year,
				semester: this.semester,
				segment: this.segment,
			})
		},
	},
	watch: {
		dashboardReport(newData) {
			if (utilities.objectValidate(newData, 'granTotal', 0) > 0) {
				setTimeout(() => {
					this.bar.$emit('init', this.barOptions)
				}, 500)
			}
		},
	},
}
</script>
